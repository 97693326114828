import React, { useState } from 'react';
import { ChevronDown, ArrowUpRight, CheckCircle } from 'lucide-react';
import { motion } from 'framer-motion';

const GhostwriterLanding = () => {
  const [openService, setOpenService] = useState(null);
  const [openFaq, setOpenFaq] = useState(null);

  const services = [
    // {
    //   title: 'Whitepapers',
    //   description: 'In-depth industry reports that establish thought leadership'
    // },
    // {
    //   title: 'Press Releases',
    //   description: 'Newsworthy announcements that grab attention'
    // },
    {
        title: 'Blog Entries',
        description: 'Detailed success stories that build authority'
      },
    {
      title: 'Social Media Content',
      description: 'Engaging posts and captions that build community'
    },
    {
      title: 'Case Studies',
      description: 'Detailed success stories that showcase results'
    },
    {
      title: 'Technical Writing',
      description: 'Complex concepts simplified for your audience'
    },
    {
      title: 'Email Newsletters',
      description: 'Campaigns and letters that build community'
    }
    // {
    //   title: 'Podcast Scripts',
    //   description: 'Audio-friendly narratives that engage listeners'
    // },

  ];

  const processes = [
    {
      number: '01',
      title: 'Discovery',
      description: 'We dive deep into your brand, audience, and goals to understand your unique story.'
    },
    {
      number: '02',
      title: 'Strategy',
      description: 'Develop a tailored content strategy that aligns with your business objectives.'
    },
    {
      number: '03',
      title: 'Creation',
      description: 'Craft compelling narratives that resonate with your target audience.'
    },
    {
      number: '04',
      title: 'Refinement',
      description: 'Polish and perfect the content through collaborative feedback and iteration.'
    }
  ];

  const pricingPlans = [
    {
      name: 'Basic Plan',
      price: '$4,999',
      features: [
        'Initial strategy definition',
        'Unlimited content requests',
        '2 content types',
        'Unlimited revisions',
        '48-hour delivery',
        'Dedicated team',
        'Monthly performance review'
      ]
    },
    {
      name: 'Premium Plan',
      price: '$9,999',
      features: [
        'Initial strategy definition',
        'Unlimited content requests',
        'All content types',
        'Unlimited revisions',
        '48-hour delivery',
        'Dedicated team',
        'Performance review as-needed'
      ]
    }
  ];

  const clients = [
    { name: 'Microsoft', logo: 'LOGO' },
    { name: 'Adobe', logo: 'LOGO' },
    { name: 'Salesforce', logo: 'LOGO' },
    { name: 'Oracle', logo: 'LOGO' },
    { name: 'Salesforce', logo: 'LOGO' },
    { name: 'Oracle', logo: 'LOGO' }
  ];

  return (
    <div className="min-h-screen bg-white">
      {/* Header */}
      <header className="sticky top-0 z-50 px-4 py-6 bg-white/80 backdrop-blur-sm lg:px-8">
        <nav className="flex items-center justify-between">
          <h1 className="relative group flex items-center gap-2">
            <span className="text-2xl font-bold tracking-tight">
              Ghost
              <span className="text-gray-400">writer</span>
            </span>
            <span className="absolute -left-6 opacity-0 group-hover:opacity-100 group-hover:translate-x-2 transition-all duration-300">
              👻
            </span>
          </h1>
          <div className="flex items-center gap-6">
            <a href="#work" className="hidden md:block text-gray-600 hover:text-black">Work</a>
            <a href="#services" className="hidden md:block text-gray-600 hover:text-black">Services</a>
            <a href="#pricing" className="hidden md:block text-gray-600 hover:text-black">Pricing</a>
            <button className="px-4 py-2 text-sm font-medium text-white bg-black rounded-full hover:bg-gray-800">
              Talk to a Human
            </button>
          </div>
        </nav>
      </header>


      {/* Hero Section */}
      <main className="px-4 py-16 lg:px-8">
        <section className="max-w-4xl mx-auto text-center">
          <h2 className="text-5xl font-bold leading-tight tracking-tight">
            Transform good ideas into
            <span className="text-gray-400"> impactful </span>
            {/* <br /> */} content
          </h2>
          {/* <p className="mt-6 text-xl text-gray-600">
            For modern marketing teams and executives with innovative stories to tell, we offer fractional content strategy and writing services.
          </p> */}
          <div className="flex flex-col items-center gap-4 mt-8 sm:flex-row sm:justify-center">
            <button className="px-6 py-3 text-white bg-black rounded-full hover:bg-gray-800">
              See Our Work
            </button>
            <button className="px-6 py-3 text-gray-700 bg-gray-100 rounded-full hover:bg-gray-200">
              Learn More
            </button>
          </div>
        </section>

        {/* Value Proposition */}
        <section className="max-w-4xl mx-auto mt-24">
          <div className="grid gap-8 md:grid-cols-2">
            {/* Left column */}
            <div className="p-8 bg-black text-white rounded-2xl">
              <h3 className="text-3xl font-bold">Powerful Stories For Modern Brands</h3>
              <div className="mt-6 flex items-center gap-2">
                <ArrowUpRight className="w-5 h-5" />
                <span className="text-gray-400">Our Mission</span>
              </div>
            </div>
            
            {/* Right column */}
            <div className="p-8 bg-gray-50 rounded-2xl flex flex-col justify-between group">
              <p className="text-lg leading-relaxed text-gray-600">
                For modern marketing teams and executives with innovative stories to tell, we offer 
                <span className="font-semibold text-black"> fractional content strategy </span> 
                and 
                <span className="font-semibold text-black"> writing services</span>.
              </p>
              <p className="mt-4 text-lg leading-relaxed text-gray-600">
                Unlike expensive, slow in-house writers or outsourced services, we deliver
                <span className="font-semibold text-black"> compelling, high-quality narratives </span>
                without the overhead.
              </p>
              <button 
                onClick={() => {
                  document.getElementById('process').scrollIntoView({ 
                    behavior: 'smooth',
                    block: 'start'
                  });
                }}
                className="mt-8 flex items-center gap-2 text-black hover:opacity-70 transition-opacity"
              >
                <span className="font-medium">Learn about our process</span>
                <ArrowUpRight className="w-5 h-5 transform group-hover:translate-x-1 group-hover:-translate-y-1 transition-transform" />
              </button>
            </div>
          </div>
        </section>


        {/* Clients */}
        {/* <section className="max-w-4xl mx-auto mt-24">
          <h2 className="text-xl font-medium text-center text-gray-600">Trusted by</h2>
          <div className="grid grid-cols-2 gap-8 mt-8 md:grid-cols-3 lg:grid-cols-6">
            {clients.map((client) => (
              <div 
                key={client.name}
                className="flex items-center justify-center p-6 bg-gray-50 rounded-xl"
              >
                <span className="text-2xl font-bold text-gray-400">{client.logo}</span>
              </div>
            ))}
          </div>
        </section> */}

        {/* Services */}
        <section id="services" className="max-w-4xl mx-auto mt-24">
          <h2 className="text-3xl font-bold">Our Services</h2>
          <p className="mt-4 text-gray-600">
          Strategic content creation that builds authority, drives growth, and connects with your target audience.          </p>
          <div className="mt-12">
            {services.map((service, index) => (
              <div 
                key={service.title}
                className="group flex items-center justify-between py-8 border-t border-gray-200 hover:px-4 transition-all duration-300"
              >
                <div>
                  <h3 className="text-xl font-medium">{service.title}</h3>
                  <p className="mt-1 text-gray-600 group-hover:text-black transition-colors">{service.description}</p>
                </div>
                <ArrowUpRight className="w-5 h-5 opacity-0 group-hover:opacity-100 transition-opacity" />
              </div>
            ))}
            <div className="border-t border-gray-200" />
          </div>
        </section>

        {/* Process */}
        <ProcessSection />

        {/* Pricing */}
        <section id="pricing" className="max-w-4xl mx-auto mt-24">
          <h2 className="text-3xl font-bold text-center">Pricing</h2>
          <p className="mt-4 text-gray-600 text-center max-w-3xl mx-auto">
            For a flat monthly fee, you receive unlimited in-depth articles tailored to your voice and vision, delivered one request at a time.
          </p>
          <div className="grid gap-8 mt-12 md:grid-cols-2">
            {pricingPlans.map((plan) => (
              <div key={plan.name} className="p-8 bg-gray-50 rounded-xl">
                <h3 className="text-2xl font-bold">{plan.name}</h3>
                <div className="mt-4">
                  <span className="text-4xl font-bold">{plan.price}</span>
                  <span className="text-gray-600">/month</span>
                </div>
                <ul className="mt-8 space-y-4">
                  {plan.features.map((feature) => (
                    <li key={feature} className="flex items-center gap-2 text-gray-600">
                      <CheckCircle className="w-5 h-5 text-black" />
                      {feature}
                    </li>
                  ))}
                </ul>
                <button className="w-full px-6 py-3 mt-8 text-white bg-black rounded-full hover:bg-gray-800">
                  Get Started
                </button>
              </div>
            ))}
          </div>
          <p className="mt-8 text-center text-gray-600">
           Have something in mind? Drop us a note. 
          </p>
        </section>

        {/* About Us Section
        <section id="about" className="max-w-4xl mx-auto mt-24">
          <h2 className="text-3xl font-bold text-center">The Team</h2>
          <div className="mt-8 grid md:grid-cols-2 gap-12">
            <div className="space-y-6">
              <p className="text-gray-600 leading-relaxed">
                We are seasoned veterans in strategy, marketing, sales, design, journalism, and content creation with decades of combined experience helping people and brands share their stories with the world. 
              </p>
              <p className="text-gray-600 leading-relaxed">
                We help executives and companies tell compelling stories to raise capital, attract top talent and carve out a place in their customers minds.
              </p>
            </div>
            <div className="grid grid-cols-2 gap-4">
              {[1, 2, 3, 4].map((i) => (
                <div key={i} className="aspect-square bg-gray-100 rounded-xl"></div>
              ))}
            </div>
          </div>
        </section> */}

        {/* FAQ Section */}
        <section id="faq" className="max-w-4xl mx-auto mt-24">
          <h2 className="text-3xl font-bold text-center">FAQ</h2>
          <div className="mt-12 space-y-4">
            {[
              {
                question: "How quickly can we start?",
                answer: "We can begin within a week of your onboarding session. You'll have your first long-form piece and social media content ready to publish within 2 weeks."
              },
              {
                question: "How do we choose topics?",
                answer: "We collaborate to define a backlog of topics while staying flexible to focus on new priorities or react to news and events."
              },
              {
                question: "What's included in the monthly fee?",
                answer: "You'll receive unlimited content requests, from long-form articles to shorter social media posts, all crafted to your specifications and designed to engage your audience. We work on 1 content request at a time, with a 48 hour turnaround time per revision."
              },
              {
                question: "Is there a minimum commitment?",
                answer: "You can pause or cancel anytime, but we recommend a commitment of at least three to six months because consistency is key to building a strong brand presence."
              },
              {
                question: "How is the content tailored to me?",
                answer: "We start with an in-depth onboarding interview to understand your voice, goals, and expertise. Share your story concepts however you'd like. Drop us a voice memo, add topics to the backlog, or schedule a brainstorm. We fit into your workflow."
              },
              {
                question: "Can I provide feedback?",
                answer: "Absolutely! You'll have the opportunity to review and provide feedback on all content before it's published, ensuring it aligns perfectly with your vision."
              },
              {
                question: "How do we ensure quality?",
                answer: "We maintain a small portfolio with limited slots to ensure high-quality content and impactful results. We align up-front on editorial strategy and work from your personal or company brand and voice. Our experienced editorial team brings decades of combined industry experience, hand-editing each draft."
              },
              {
                question: "How is billing managed?",
                answer: "Services are billed monthly, pause or cancel anytime. We ask for a 3 month minimum commitment to start. You can pay via credit card or request an invoice."
              }
            ].map((faq, index) => (
              <div key={index} className="border-b border-gray-200">
                <button
                  onClick={() => setOpenFaq(openFaq === index ? null : index)}
                  className="w-full py-6 flex items-center justify-between text-left"
                >
                  <span className="text-lg font-medium">{faq.question}</span>
                  <ChevronDown 
                    className={`w-5 h-5 transform transition-transform ${
                      openFaq === index ? 'rotate-180' : ''
                    }`}
                  />
                </button>
                {openFaq === index && (
                  <div className="pb-6">
                    <p className="text-gray-600">{faq.answer}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </section>


        {/* CTA Section */}
        <section className="max-w-4xl mx-auto mt-24 text-center">
          <h2 className="text-4xl font-bold">Ready to tell your story?</h2>
          <p className="mt-4 text-xl text-gray-600">
            Let's create something amazing together.
          </p>
          <button className="px-8 py-4 mt-8 text-lg font-medium text-white bg-black rounded-full hover:bg-gray-800">
            Talk to a Human
          </button>
        </section>
      </main>

      {/* Footer */}
      <footer className="px-4 py-16 mt-24 bg-black lg:px-8">
        <div className="max-w-4xl mx-auto">
          <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
            <div>
              <h3 className="text-xl font-bold text-white">Ghostwriter</h3>
              <p className="mt-4 text-gray-400">
                Professional content creation for forward-thinking brands.
              </p>
            </div>
            <div>
              <h4 className="text-sm font-medium text-gray-400">Contact</h4>
              <a href="mailto:hello@ghostwriter.com" className="block mt-4 text-white hover:text-gray-200">
                hello@ghostwriter.com
              </a>
            </div>
            <div>
              <h4 className="text-sm font-medium text-gray-400">Social</h4>
              <div className="flex gap-4 mt-4">
                <a href="#" className="text-white hover:text-gray-200">Twitter</a>
                <a href="#" className="text-white hover:text-gray-200">LinkedIn</a>
              </div>
            </div>
          </div>
          <div className="pt-8 mt-16 border-t border-gray-800">
            <p className="text-sm text-gray-400">
              © {new Date().getFullYear()} Ghostwriter. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

const ProcessSection = () => {
  const processes = [
    {
      number: '01',
      title: 'Discovery',
      description: 'We dive deep into your brand, audience, and goals to understand your unique story.'
    },
    {
      number: '02',
      title: 'Strategy',
      description: 'Develop a tailored content strategy that aligns with your business objectives.'
    },
    {
      number: '03',
      title: 'Creation',
      description: 'Craft compelling narratives that resonate with your target audience.'
    },
    {
      number: '04',
      title: 'Refinement',
      description: 'Polish and perfect the content through collaborative feedback and iteration.'
    }
  ];

  return (
    <section id="process" className="max-w-4xl mx-auto mt-24 scroll-mt-20">
      <div className="animate-fade-in">
        <h2 className="text-3xl font-bold">Our Process</h2>
        <p className="mt-4 text-gray-600 max-w-2xl">
          A systematic approach to creating impactful content that drives results.
        </p>
      </div>

      <div className="mt-16 space-y-16">
        {processes.map((process, index) => (
          <div
            key={process.number}
            className="relative group"
          >
            <div className={`flex items-start gap-8 ${index % 2 === 0 ? '' : 'flex-row-reverse'}`}>
              {/* Number */}
              <div className="flex-shrink-0 w-24">
                <span className="text-5xl font-bold text-black/10 transition-transform duration-300 group-hover:scale-110 inline-block">
                  {process.number}
                </span>
              </div>

              {/* Content */}
              <div className="flex-1">
                <div className="p-8 bg-gray-50 rounded-2xl transition-all duration-300 hover:-translate-y-2 hover:bg-black hover:text-white group/card">
                  <h3 className="text-xl font-bold">{process.title}</h3>
                  <p className="mt-2 transition-colors duration-300 group-hover/card:text-gray-300">
                    {process.description}
                  </p>
                  <div className="mt-4 flex items-center gap-2 text-sm opacity-0 transition-opacity duration-300 group-hover/card:opacity-100">
                    <span>Learn more</span>
                    <ArrowUpRight className="w-4 h-4 transition-transform duration-300 group-hover/card:translate-x-1 group-hover/card:-translate-y-1" />
                  </div>
                </div>
              </div>
            </div>

            {/* Connector Line (except for last item) */}
            {index !== processes.length - 1 && (
              <div className="absolute left-12 top-24 w-[1px] h-24 bg-gray-200 -z-10" />
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default GhostwriterLanding;
